import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import MyError from "@app/components/MyError/MyError";
import {
  ACCEPT_USER_REQUEST,
  USERS_CONNECTION,
} from "@app/pages/Users/Users.graphql";
import {
  Users_ConnectionQuery,
  Users_ConnectionQueryVariables,
  Accept_User_RequestMutation,
  Accept_User_RequestMutationVariables,
} from "@app/types/generated";
import { formatDate } from "@app/utils/formatDate";
import { Button, PageHeader, Table, Tag } from "antd";
import React from "react";
import { CheckOutlined } from "@ant-design/icons";
import { popup } from "@app/tools/popup";
import { usePagination } from "@app/hooks/usePagination";
import MyPagination from "@app/components/MyPagination/MyPagination";

export const useUsersVariables = (): Users_ConnectionQueryVariables => {
  const { first, skip } = usePagination();

  return {
    first,
    skip,
  };
};

const Users = () => {
  const variables = useUsersVariables();
  const { loading, error, data } = useQuery<
    Users_ConnectionQuery,
    Users_ConnectionQueryVariables
  >(USERS_CONNECTION, {
    variables,
  });
  const [acceptUserRequest] = useMutation<
    Accept_User_RequestMutation,
    Accept_User_RequestMutationVariables
  >(ACCEPT_USER_REQUEST);

  const statusColors = {
    REJECTED: "error",
    REQUESTED: "processing",
    CREATED: "success",
  };

  if (error) return <MyError error={error} />;
  const dataSource: {
    index: number;
    name: string;
  }[] =
    data?.usersConnection?.edges?.map((edge, index) => {
      const nodeId = edge?.node?.id;
      const status = edge?.node?.createDataBaseStatus;
      return {
        key: nodeId,
        index: index + 1,
        name: edge?.node?.name || "",
        nameKana: edge?.node?.nameKana || "",
        employeesCount: edge?.node?.employeesCount,
        plan: <Tag color="success">スタート</Tag>,
        status: status && <Tag color={statusColors[status]}>{status}</Tag>,
        startDate: formatDate(edge?.node?.createdAt),
        endDate: formatDate(edge?.node?.createdAt),
        action: status === "REQUESTED" && (
          <Formik
            initialValues={{}}
            onSubmit={async () => {
              try {
                if (nodeId) {
                  await acceptUserRequest({
                    variables: {
                      userId: nodeId,
                    },
                  });
                }
              } catch (err) {
                popup.error(err);
              }
            }}
          >
            {(formikProps) => {
              const { isSubmitting, handleSubmit } = formikProps;
              return (
                <div>
                  <Button
                    type="primary"
                    loading={isSubmitting}
                    icon={<CheckOutlined />}
                    onClick={() => handleSubmit()}
                  ></Button>
                </div>
              );
            }}
          </Formik>
        ),
      };
    }) || [];

  const total = data?.total?.aggregate?.count || 0;

  return (
    <div>
      <PageHeader title={"Users"} />
      <Table
        pagination={false}
        columns={[
          {
            dataIndex: "index",
            title: "#",
          },
          {
            dataIndex: "name",
            title: "Name",
          },
          {
            dataIndex: "nameKana",
            title: "nameKana",
          },
          {
            dataIndex: "employeesCount",
            title: "Employees",
          },
          {
            dataIndex: "plan",
            title: "Plan",
          },
          {
            dataIndex: "status",
            title: "Status",
          },
          {
            dataIndex: "startDate",
            title: "Start Date",
          },
          {
            dataIndex: "endDate",
            title: "End Date",
          },
          {
            dataIndex: "action",
            title: "Action",
          },
        ]}
        dataSource={dataSource}
        loading={loading}
      />
      <MyPagination total={total} />
    </div>
  );
};

export default Users;
