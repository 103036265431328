import { Button, Form, Input } from "antd";
import { FormInstance } from "antd/lib/form";
import { Formik } from "formik";
import React, { FC } from "react";

const ScNewsForm: FC<{ form: FormInstance<any> }> = ({ form }) => {
  return (
    <Form
      initialValues={{}}
      onFinish={() => {}}
      form={form}
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
    >
      <Form.Item
        name="title"
        label="タイトル"
        rules={[
          {
            max: 255,
            required: true,
            message: "",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="link"
        label="リンク"
        rules={[
          {
            type: "url",
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

export default ScNewsForm;
