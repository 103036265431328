import Sidebar from "@app/layout/Sidebar";
import { useAuthContext } from "@app/provider/MyAuthProvider";
import { Layout } from "antd";
import React, { FC } from "react";
import { ReactComponent as Logo } from "@app/assets/Logo.svg";
import Icon from "@ant-design/icons";
const { Content, Header, Sider } = Layout;

const MyLayout: FC<any> = ({ children }) => {
  const { user } = useAuthContext();

  return (
    <Layout>
      <Header className="layout-header">
        <Logo className="layout-logo" />
        <div>{user?.name}</div>
      </Header>
      <Layout>
        <Sider className="layout-sider">
          <Sidebar />
        </Sider>
        <Content className="layout-content">{children}</Content>
      </Layout>
    </Layout>
  );
};

export default MyLayout;
